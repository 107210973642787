import { useCallback, useEffect, useMemo, useState } from 'react'
import type { CampaignActivityData, CampaignActivityItem, OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, Breadcrumbs, Tab, TabList, TabPanel, Tabs, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages } from '@sevenrooms/marketing'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { oneTimeEmailCenterMessages } from '../../locales'
import { CampaignPerformanceClicks } from '../CampaignPerformanceClicks'
import { CampaignPerformanceOpens } from '../CampaignPerformanceOpens'
import { CampaignPerformanceOverview } from '../CampaignPerformanceOverview'
import { CampaignPerformanceReservations } from '../CampaignPerformanceReservations'

export interface OneTimeEmailCampaignDeepDiveViewerFormProps {
  emailCampaign: OneTimeEmailCampaignType
  campaignActivityData: CampaignActivityData
}

export function OneTimeEmailCampaignDeepDiveViewerForm({
  emailCampaign,
  campaignActivityData,
}: OneTimeEmailCampaignDeepDiveViewerFormProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { params, query } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.viewDeepDive)
  const { campaignId } = params
  const campaignActivity = campaignActivityData.campaignActivity[campaignId] as CampaignActivityItem
  const isEmpty = Object.values(campaignActivity).every(metric => !metric)
  const tabs: { key: string; title: string }[] = useMemo(
    () => [
      {
        key: 'overview',
        title: formatMessage(oneTimeEmailCenterMessages.overviewTab),
      },
      {
        key: 'opens',
        title: formatMessage(oneTimeEmailCenterMessages.opensTab),
      },
      {
        key: 'clicks',
        title: formatMessage(oneTimeEmailCenterMessages.clicksTab),
      },
      {
        key: 'reservations',
        title: formatMessage(oneTimeEmailCenterMessages.reservationsTab),
      },
    ],
    [formatMessage]
  )

  const activeTabIndex = tabs.findIndex(tab => tab.key === query.tab)
  const [selectedIndex, setSelectedIndex] = useState(activeTabIndex !== -1 ? activeTabIndex : 0)

  const { venue } = useVenueContext()

  const onCampaignDuplicateClick = useCallback(
    (campaignId: string) => {
      if (venue) {
        nav.push(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail, {
          params: { venueKey: venue.urlKey },
          query: { campaignToDuplicateId: campaignId, parentTemplateId: '' },
        })
      }
    },
    [nav, venue]
  )

  // Handles browser back/forward navigation for tabs
  useEffect(() => {
    if (query.tab) {
      if (query.tab !== tabs[selectedIndex]?.key) {
        setSelectedIndex(tabs.findIndex(tab => tab.key === query.tab))
      }
    } else {
      setSelectedIndex(0)
    }
  }, [selectedIndex, query.tab, tabs])

  const onTabChange = useCallback(
    (index: number) => {
      setSelectedIndex(index)
      const tab = tabs[index]

      if (tab) {
        nav.push(routes.manager2.marketing.oneTimeEmailCenter.viewDeepDive, {
          params: { venueKey: venue.urlKey, campaignId },
          query: { tab: tab.key },
        })
      }
    },
    [tabs, nav, venue, campaignId]
  )

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(emailBuilderMessages.viewEmailCampaign)} />
      <SettingsPageContent
        headerWidth="calc(100% - 274px)"
        secondHeaderMaxWidth="100%"
        title={
          <VStack>
            {emailCampaign ? `${formatMessage(commonMessages.view)} ${emailCampaign.campaignName}` : ''}
            {!isEmpty && (
              <Box mt="lm">
                <Tabs onSelect={onTabChange} selectedIndex={selectedIndex}>
                  <TabList>
                    {tabs.map(tab => (
                      <Tab key={tab.key} data-test={`tab-${tab.key}`}>
                        {tab.key === tabs[selectedIndex]?.key ? tab.title : <Text>{tab.title}</Text>}
                      </Tab>
                    ))}
                  </TabList>
                </Tabs>
              </Box>
            )}
          </VStack>
        }
        breadcrumbs={
          <Breadcrumbs>
            <Link
              to={nav.href(routes.manager2.marketing.oneTimeEmailCenter, {
                params: { venueKey: venue.urlKey },
                queryMode: 'clear',
              })}
            >
              {formatMessage(oneTimeEmailCenterMessages.oneTimeEmailCenterTitle)}
            </Link>
            <Text>{formatMessage(oneTimeEmailCenterMessages.oneTimeEmailCenterCampaignPerformance)}</Text>
          </Breadcrumbs>
        }
        {...(emailCampaign.usingTopolEditor && {
          actions: (
            <Button type="submit" data-test="one-time-email-builder-duplicate-button" onClick={() => onCampaignDuplicateClick(campaignId)}>
              {formatMessage(commonMessages.duplicate)}
            </Button>
          ),
        })}
      >
        <Box pl="lm" pr="lm">
          {isEmpty ? (
            <CampaignPerformanceOverview emailCampaign={emailCampaign} activity={campaignActivity} isEmpty />
          ) : (
            <Tabs selectedIndex={selectedIndex}>
              <TabPanel>
                <CampaignPerformanceOverview emailCampaign={emailCampaign} activity={campaignActivity} isEmpty={false} />
              </TabPanel>
              <TabPanel>
                <CampaignPerformanceOpens emailCampaign={emailCampaign} activity={campaignActivity} />
              </TabPanel>
              <TabPanel>
                <CampaignPerformanceClicks emailCampaign={emailCampaign} activity={campaignActivity} />
              </TabPanel>
              <TabPanel>
                <CampaignPerformanceReservations emailCampaign={emailCampaign} />
              </TabPanel>
            </Tabs>
          )}
        </Box>
      </SettingsPageContent>
    </>
  )
}
